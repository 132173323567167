import dayjs from "dayjs"
import relativeTime from "dayjs/plugin/relativeTime"
import utc from "dayjs/plugin/utc"
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import DeleteIcon from '@mui/icons-material/Delete'
import BurstModeIcon from '@mui/icons-material/BurstMode';
import {Button, Chip, Grid, IconButton, Paper, Tooltip, Typography} from "@mui/material";

dayjs.extend(relativeTime)
dayjs.extend(utc)
// @ts-ignore
const CampWatchCard = ({campwatch, campground, handleDeleteCampwatch}) => {
    const availability = campwatch.is_triggering ? "Campspots Available" : "No Campspots"
    const availabilityButtonBgColor = campwatch.is_triggering ? "bg-lime-500" : "bg-gray-500"
    let sinceTime = ""
    if (campwatch.last_checked) {
        sinceTime = "Last checked " + dayjs.utc(campwatch.last_checked).fromNow()
    }

    if (campground === undefined) {
        debugger
    }

    const startDate = dayjs(campwatch.start_date).format("MMM D, YYYY")
    const startDateWithDay = dayjs(campwatch.start_date).format("ddd MMM D, YYYY")
    const endDate = dayjs(campwatch.end_date).format("MMM D, YYYY")
    const numberOfDays = dayjs(campwatch.end_date).diff(dayjs(campwatch.start_date), "days")
    return  (
        <Paper
            sx={{
                padding: "20px",
                maxWidth: "md",
                marginTop: "20px"
            }}
            elevation={2}
        >
            <Grid container
                  justifyContent="space-between"
            >
                <Typography variant="h6">{campground.name}</Typography>
                <Typography variant="subtitle1">{startDateWithDay} ({numberOfDays} nights)</Typography>
            </Grid>

            <Grid container spacing={2} alignItems='center'>

                <Grid item>
                    <Typography
                        sx={{
                            marginTop: "2",
                            color: "gray"
                        }}
                        variant="body2">
                        {sinceTime}
                    </Typography>
                </Grid>
                <Grid item>
                    <Tooltip title={ campwatch.is_partial_watch ?
                        "Will notify if any dates in this range become available at this site" :
                        "Will only notify if all the dates in this range become available"}>
                        {/*<BurstModeIcon color={ campwatch.is_partial_watch ? "success" : "disabled"}/>*/}
                        <Chip size="small"
                              variant="outlined"
                              color="info"
                              label={campwatch.is_partial_watch ? "Partial" : "Strict"}/>
                    </Tooltip>
                </Grid>
            </Grid>
            <Grid container
                  alignItems="center"
                  justifyContent="space-between"
            >
                <Grid item>
                    <Button color="primary" onClick={() => handleDeleteCampwatch(campwatch.id)}>
                        <DeleteOutlineIcon/>Delete
                    </Button>
                </Grid>
                <Grid item>
                    <Chip
                        label={availability}
                        color={campwatch.is_triggering ? "success" : "default"}
                        clickable={true}
                        onClick={() => window.open(campground.url)}
                    />
                </Grid>
            </Grid>
        </Paper>
    )
}

export default CampWatchCard