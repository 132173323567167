import {Divider, List, ListItem, ListItemIcon, ListItemText, Paper} from "@mui/material";
import {Link, Route, Routes} from "react-router-dom";
import ListAltIcon from "@mui/icons-material/ListAlt";
import PersonIcon from "@mui/icons-material/Person";
import React from "react";

export const DesktopNavigation = () => {
    return (
        <div className="flex flex-col w-64 h-screen px-4 py-8 bg-gray-50 border-r hidden sm:block">
            <h2 className="text-3xl font-semibold text-gray-800 mb-4">GRABACAMP</h2>
            <div className="flex justify-center">
                <img className="max-h-10 mb-4 mr-4" src="grabacamp-logo-no-legend.svg" alt="logo" />
            </div>
            <Divider/>
            <Paper elevation={0} sx={{background: 'rgb(249 250 251 / var(--tw-bg-opacity))'}}>
                <List>
                    <ListItem button component={Link} to="/">
                        <ListItemIcon>
                            <ListAltIcon/>
                        </ListItemIcon>
                        <ListItemText primary="Watches"/>
                    </ListItem>
                    <ListItem button component={Link} to="/account">
                        <ListItemIcon>
                            <PersonIcon/>
                        </ListItemIcon>
                        <ListItemText primary="Account"/>
                    </ListItem>
                </List>
            </Paper>
        </div>
    )
}