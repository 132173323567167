import Account from "./Account";
import CampWatches from "./CampWatches";
import CampWatchAdder from "./CampWatchAdder";
import { supabase } from "../supabaseClient";
import React, { useEffect, useState } from "react";
import { Routes, Route, Link } from "react-router-dom";
import { MobileNavigation } from "./MobileNavigation";
import { DesktopNavigation } from "./DesktopNavigation";
import { Box } from "@mui/material";

// @ts-ignore
const Main = ({ session }) => {
    const [campgrounds, setCampgrounds] = useState([])
    const [campWatches, setCampwatches] = useState([])
    const [maxWatches, setMaxWatches] = useState(5)

    useEffect(() => {
        void fetchCampwatches()
        void fetchCampgrounds()
        void fetchTier()
    }, [])

    const fetchTier = async () => {
        const response = await supabase.from("users_tiers").select("*")
        if (response.data?.length === 1 && response.data[0].max_watches) {
            setMaxWatches(response.data[0].max_watches)
        }
    }

    const fetchCampgrounds = async () => {
        // @ts-ignore
        let { data, error } = await supabase.from("campgrounds").select("*")
        if (error) {
            console.log("error", error)
        } else {
            // @ts-ignore
            setCampgrounds(data)
        }
    }

    const fetchCampwatches = async () => {
        let result = await supabase.from('campwatches').select().order('start_date', { ascending: true })
        // @ts-ignore
        let { data, error } = result
        if (error) {
            console.log("error", error)
        } else {
            // console.log("campwatches", data)
            // @ts-ignore
            setCampwatches(data)
        }
    }

    const handleAddCampwatch = async (campgroundId: number, startDate: Date, endDate: Date, isPartial: boolean) => {
        const { data, error } = await supabase.from('campwatches').insert({
            start_date: startDate,
            end_date: endDate,
            campground_id: campgroundId,
            is_partial_watch: isPartial,
            owner_id: session.user.id,
        })
        if (error) {
            console.log(error)
        } else {
            await fetchCampwatches()
        }
    }

    const handleDeleteCampwatch = async (id: string) => {
        const { data, error } = await supabase.from('campwatches').delete().match({ id: id })
        if (error) {
            console.log(error)
        } else {
            await fetchCampwatches()
        }
    }

    return (
        <div>
            {/* Mobile Navigation Header - top bar */}
            <MobileNavigation />

            {/* Desktop Navigation Header - side bar */}
            <Box sx={{
                display: "flex",
                flexDirection: "row",
            }}>
                <DesktopNavigation />
                <Box
                    sx={{
                        padding: "2.5rem",
                        width: "100%",
                        height: "100vh",
                        overflow: "auto",
                    }}
                >
                    <Routes>
                        <Route path="/" element={
                            <CampWatches
                                campWatches={campWatches}
                                campgrounds={campgrounds}
                                maxWatches={maxWatches}
                                handleDeleteCampwatch={handleDeleteCampwatch}
                            />
                        } />
                        <Route path="/addwatch" element={
                            <CampWatchAdder
                                handleAddCampwatch={handleAddCampwatch}
                                campgrounds={campgrounds}
                            />
                        } />
                        <Route path="/account" element={<Account session={session} />} />
                    </Routes>
                </Box>
            </Box>
        </div>)
}

export default Main