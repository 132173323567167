import MenuIcon from "@mui/icons-material/Menu";
import {Link} from "react-router-dom";
import React, {useState} from "react";

export const MobileNavigation = () => {
    const [mobileMenuExpanded, setMobileMenuExpanded] = useState(false)
    return (
        <nav className="bg-white shadow block sm:hidden">
            <div className="container px-6 py-4 mx-auto md:flex md:justify-between md:items-center bg-gray-50">
                <div className="flex items-center justify-between">
                    <div className="flex flex-row">
                        <a className="text-2xl font-bold text-gray-800 transition-colors duration-200 transform lg:text-3xl hover:text-gray-700"
                           href="#">GRABACAMP</a>
                        <img className="max-h-8 ml-5" src="grabacamp-logo-no-legend.svg" alt="logo" />
                    </div>

                    <div className="flex md:hidden">
                        <button type="button"
                                className="text-gray-500 hover:text-gray-600 focus:outline-none focus:text-gray-600"
                                aria-label="toggle menu"
                                onClick={() => {setMobileMenuExpanded(!mobileMenuExpanded)}}
                        >
                            <MenuIcon/>
                        </button>
                    </div>
                </div>

                {mobileMenuExpanded && <div className="items-center md:flex">
                    <div className="flex flex-col md:flex-row md:mx-6">
                        <Link
                            className="my-1 text-sm font-medium text-gray-700 transition-colors duration-200 transform hover:text-blue-500 md:mx-4 md:my-0"
                            to="/">Watches</Link>
                        <Link
                            className="my-1 text-sm font-medium text-gray-700 transition-colors duration-200 transform hover:text-blue-500 md:mx-4 md:my-0"
                            to="/account">Account</Link>
                    </div>
                </div>
                }
            </div>
        </nav>
    )
}