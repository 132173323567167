import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import {useEffect, useState} from "react";


function Copyright(props: any) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://mui.com/">
        Your Website
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

// @ts-ignore
export const SignIn = ({handleEmailPasswordLogin, handleMagicLinkLogin, loading, loginError}) => {
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [useMagicLinkLogin, setUseMagicLinkLogin] = useState(false)
    const [showLoginError, setShowLoginError] = useState(false)
    useEffect(() => {
        setShowLoginError(false)
    }, [email, password])

    const possibleSigninLinks = [
        "https://www.youtube.com/watch?v=pxw-5qfJ1dk", // shresksophone
        "https://www.youtube.com/watch?v=dQw4w9WgXcQ", // rick roll
        "https://www.youtube.com/watch?v=UhHhXukovMU", // italian spiderman
        "https://www.youtube.com/watch?v=PAnKl7862qc", // wednesday my dude
        "https://www.youtube.com/watch?v=FinKpM8sRHc", // planking
        "https://www.youtube.com/watch?v=WEv0bl7oodU", // bye little sebastian
        "https://www.youtube.com/watch?v=0A20vfx-sO0", // good variant
    ]
    const randomSigningLink = possibleSigninLinks[Math.floor(Math.random() * possibleSigninLinks.length)]


    return (<Container component="main" maxWidth="xs">
        <CssBaseline/>
        <Box
            sx={{
                marginTop: 8,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
            }}
        >
            <Avatar sx={{m: 1, bgcolor: 'secondary.main'}}>
                <LockOutlinedIcon/>
            </Avatar>
            <Typography component="h1" variant="h5">
                Sign in
            </Typography>
            <Box component="form" onSubmit={() => {console.log("on submit")}} noValidate sx={{mt: 1}}>
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label="Email Address"
                    name="email"
                    autoComplete="email"
                    onChange={(e) => setEmail(e.target.value)}
                    autoFocus
                />
                {!useMagicLinkLogin &&
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Password"
                        type="password"
                        id="password"
                        autoComplete="current-password"
                        onChange={(e) => setPassword(e.target.value)}
                    />
                }
                {/*<FormControlLabel*/}
                {/*    control={<Checkbox value="remember" color="primary"/>}*/}
                {/*    label="Remember me"*/}
                {/*/>*/}
                {loginError && showLoginError &&
                    <Typography color="secondary.main">
                        {loginError}
                    </Typography>
                }
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{mt: 3, mb: 2}}
                    disabled={loading}
                    onClick={(e) => {
                        e.preventDefault()
                        setShowLoginError(true)
                        if (useMagicLinkLogin) {
                            handleMagicLinkLogin(email)
                        }else{
                            handleEmailPasswordLogin(email, password)
                        }
                    }}
                >
                    Sign In
                </Button>
                <Grid container>
                        {useMagicLinkLogin ?
                            <Grid item>
                                <Typography>Login with a magic link sent to your email</Typography>
                            </Grid>
                            :
                            <Grid item xs>
                                <Link
                                    variant="body2"
                                    onClick={() => setUseMagicLinkLogin(true)}
                                >
                                    Forgot password?
                                </Link>
                            </Grid>
                        }
                    <Grid item>
                        <Link href={randomSigningLink} variant="body2">
                            {"Don't have an account? Sign Up"}
                        </Link>
                    </Grid>
                </Grid>
            </Box>
        </Box>
        {/*<Copyright sx={{mt: 8, mb: 4}}/>*/}
    </Container>)
}
