import { useEffect, useState } from "react"
import CampWatchCard from "./CampWatchCard"
import {Link, useNavigate} from "react-router-dom"
import {Alert, AlertTitle, Button, Typography} from "@mui/material"
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import {supabase} from "../supabaseClient";

// @ts-ignore
const CampWatches = ({campWatches, campgrounds, handleDeleteCampwatch, maxWatches}) => {
    const [campwatchCards, setCampwatchCards] = useState([])
    const [hasProvidedSms, setHasProvidedSms] = useState<boolean>(false)
    const [checkedForSms, setCheckedForSms] = useState<boolean>(false)
    let navigate = useNavigate();
    useEffect(() => {
        if (campgrounds.length > 0) {
            setCampwatchCards(campWatches.map((campwatch:any) => {
                const campground: any = campgrounds.find((campground:any) => campground.id === campwatch.campground_id)
                return <CampWatchCard
                    key={campwatch.id}
                    campwatch={campwatch}
                    campground={campground}
                    handleDeleteCampwatch={handleDeleteCampwatch} />
            }))
        }
    }, [campWatches, campgrounds, handleDeleteCampwatch])
    useEffect(() => {
        void checkIfItHasSms()
    }, [])

    async function checkIfItHasSms() {
        try {
            const user = supabase.auth.user()
            if(user == null) {
                return
            }
            let { data, error, status } = await supabase
                .from('profiles')
                .select(`username, sms_number`)
                .eq('id', user.id)
                .single()

            if (error && status !== 406) {
                throw error
            }
            if (data && data.sms_number) {
                setHasProvidedSms(true)
            }
        } catch (error: any) {

        } finally {
            setCheckedForSms(true)
        }
    }

    return <div>
        { checkedForSms && !hasProvidedSms &&
            <Alert
                sx={{
                    maxWidth: "md",
                }}
                severity="warning">
                <AlertTitle>Enter your SMS number</AlertTitle>
                <p>
                    You have not provided your SMS number. Until then, you will not receive sms notifications for
                    your camp watches. Add it from your account settings:
                </p>
                <Button
                    color="warning"
                    onClick={() => navigate("/account")}>
                Account Settings</Button>
            </Alert>
        }

        { campwatchCards.length < maxWatches ? (<div className="mb-4">
            <Button
                startIcon={<AddCircleOutlineIcon />}
                onClick={() => navigate("/addwatch")}
            >
                Add a New Watch
            </Button>
        </div>) : (<div
            className="flex w-full max-w-sm mt-4 mb-4 overflow-hidden bg-white rounded-lg shadow-md">
            <div className="flex items-center justify-center w-12 bg-blue-500">
                <svg className="w-6 h-6 text-white fill-current" viewBox="0 0 40 40"
                     xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M20 3.33331C10.8 3.33331 3.33337 10.8 3.33337 20C3.33337 29.2 10.8 36.6666 20 36.6666C29.2 36.6666 36.6667 29.2 36.6667 20C36.6667 10.8 29.2 3.33331 20 3.33331ZM21.6667 28.3333H18.3334V25H21.6667V28.3333ZM21.6667 21.6666H18.3334V11.6666H21.6667V21.6666Z"/>
                </svg>
            </div>

            <div className="px-4 py-2 -mx-3">
                <div className="mx-3">
                    <span className="font-semibold text-blue-500">Info</span>
                    <p className="text-sm text-gray-600">You are using all your allowed watches.</p>
                </div>
            </div>
        </div>)}

        <div className="mb-4">
            {campwatchCards}
        </div>
    </div>
}

export default CampWatches