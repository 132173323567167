import React, {useEffect, useState} from 'react';
import {createClient, Session} from '@supabase/supabase-js'
import Auth from "./Auth"
import { supabase } from "./supabaseClient"
import Main from "./components/Main";
import { Routes, Route, Link, useLocation } from "react-router-dom";
import {ThemeProvider} from "@mui/material";
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import theme from "./themes/MuiTheme";
import {LocalizationProvider} from "@mui/lab";
import {useTheme} from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";


function useQuery() {
  const { search } = useLocation();
  console.log(search);

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

function App() {
  const [session, setSession] = useState<Session|null>(null)
  //#access_token=x&refresh_token=y&expires_in=z&token_type=bearer&type=recovery
  let query = useQuery()
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const classesForAuthScreen = isSmallScreen ? "" : "flex flex-row"


  useEffect(() => {
    setSession(supabase.auth.session())
    supabase.auth.onAuthStateChange((_event, session) => {
      setSession(session)
    })
  }, [])
    return (<div>
      <ThemeProvider theme={theme}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          {!session ? <div className={classesForAuthScreen} >
                <Auth></Auth>
            { !isSmallScreen && <div className="flex flex-grow m-10">
                  <img src="grabacamp-logo.svg" alt="logo" />
                </div>}
              </div> :
              <Main session={session} />}
        </LocalizationProvider>
      </ThemeProvider>
    </div>)
}

export default App;
