import {useEffect, useState} from "react"
import "react-datepicker/dist/react-datepicker.css"
import { useNavigate } from "react-router-dom";
import {Autocomplete, Box, Button, FormControlLabel, Grid, Paper, Switch, TextField, Typography} from "@mui/material";
import { StaticDatePicker } from '@mui/lab'
import { DatePicker } from '@mui/lab'
// import {supabase} from "../supabaseClient";

// @ts-ignore
const CampWatchAdder = ({handleAddCampwatch, campgrounds}) => {
    const [startDate, setStartDate] = useState<Date|null>(new Date())
    const [endDate, setEndDate] = useState<Date|null>(new Date())
    const [isPartial, setIsPartial] = useState(true)
    const [campgroundId, setCampgroundId] = useState<number|undefined>(undefined)
    const [addEnabled, setAddEnabled] = useState(false)
    const navigate = useNavigate()

    const _handleAddCampwatch = async () => {
        startDate?.setHours(0, 0, 0, 0)
        endDate?.setHours(0, 0, 0, 0)
        await handleAddCampwatch(campgroundId, startDate, endDate, isPartial)
        navigate("/")
    }

    useEffect(() => {
        if (startDate && endDate && startDate > endDate) {
            setEndDate(startDate)
        }
    }, [startDate, endDate])

    useEffect(() => {
        if (campgroundId && startDate && endDate) {
            setAddEnabled(true)
        } else {
            setAddEnabled(false)
        }
    }, [campgroundId, startDate, endDate])

    // @ts-ignore
    const campgroundOptionsMui = campgrounds.map(campground => {
        return {id: campground.id, label: campground.name}
    })

    return (<Paper
            elevation={2}
            sx={{
                marginTop: '1rem',
                maxWidth: '900px',
            }}>
                <Box sx={{
                    padding: '1rem',
                }}>
                    {/*<CssBaseline />*/}
                    <Box
                        sx={{
                            marginTop: 2,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'left',
                        }}
                    >
                        <Typography component="h1" variant="h5">
                            Add Campwatch
                        </Typography>
                        <Box component="form" noValidate sx={{ mt: 3 }}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={12}>
                                    <Autocomplete
                                        renderInput={(params) =>
                                            <TextField {...params} fullWidth label="Campground"/>
                                        }
                                        options={campgroundOptionsMui}
                                        onChange={(event, value) => {
                                            if (value) {
                                                // @ts-ignore
                                                setCampgroundId(value.id)
                                            }else{
                                                setCampgroundId(undefined)
                                            }
                                        }
                                        }
                                        isOptionEqualToValue={(option, value) => {
                                            // @ts-ignore
                                            return option.id === value.id
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12}>
                                    <FormControlLabel control={
                                        <Switch checked={isPartial} onChange={(evt) => setIsPartial(evt.target.checked)}/>
                                    } label="Notify if campsites become available partially on the selected dates" />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <DatePicker
                                        label="Start Date"
                                        value={startDate}
                                        onChange={(date) => setStartDate(date)}
                                        renderInput={(params) =>
                                            <TextField {...params} fullWidth/>
                                        }
                                        // format="MM/dd/yyyy"
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <DatePicker
                                        label="End Date"
                                        value={endDate}
                                        onChange={(date) => setEndDate(date)}
                                        renderInput={(params) =>
                                            <TextField {...params} fullWidth/>
                                        }
                                        // format="MM/dd/yyyy"
                                    />

                                </Grid>
                            </Grid>
                            <Button
                                variant="contained"
                                sx={{ mt: 3, mb: 2 }}
                                disabled={!addEnabled}
                                onClick={_handleAddCampwatch}
                            >
                                Add it
                            </Button>
                            <Button
                                onClick={() => navigate("/")}
                                sx={{ mt: 3, mb: 2, ml: 5 }}
                            >
                                Cancel
                            </Button>
                        </Box>
                    </Box>
                </Box>
            </Paper>
    )}

export default CampWatchAdder