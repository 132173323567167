import { useState, useEffect } from 'react'
import { supabase } from '../supabaseClient'
import {
  Box,
  Button,
  Grid,
  TextField,
  Typography,
  Paper,
} from "@mui/material";
import LogoutIcon from '@mui/icons-material/Logout';

// @ts-ignore
export default function Account({ session }) {
  const [loading, setLoading] = useState(true)
  const [username, setUsername] = useState<string|null>(null)
  const [sms_number, setSmsNumber] = useState<string|null>(null)
  const [avatar_url, setAvatarUrl] = useState(null)

  const [changePasswordMessage, setChangePasswordMessage] = useState<string|null>(null)
  const [changePasswordButtonEnabled, setChangePasswordButtonEnabled] = useState<boolean>(false)
  const [newPassword, setNewPassword] = useState<string|null>(null)
  const [newPasswordConfirm, setNewPasswordConfirm] = useState<string|null>(null)

  useEffect(() => {
    if (newPassword && newPasswordConfirm && newPassword === newPasswordConfirm) {
      setChangePasswordButtonEnabled(true)
    } else {
      setChangePasswordButtonEnabled(false)
    }
  }, [newPassword, newPasswordConfirm])


  useEffect(() => {
    void getProfile()
  }, [session])

  async function getProfile() {
    try {
      setLoading(true)
      const user = supabase.auth.user()
        if(user == null) {
          setLoading(false)
          return
        }
        let { data, error, status } = await supabase
        .from('profiles')
        .select(`username, sms_number`)
        .eq('id', user.id)
        .single()

      if (error && status !== 406) {
        throw error
      }

      if (data) {
        setUsername(data.username)
        setSmsNumber(data.sms_number)
      }
    } catch (error: any) {
      alert(error.message)
    } finally {
      setLoading(false)
    }
  }

  // @ts-ignore
  async function updatePassword({newPassword}) {
    const { user, error } = await supabase.auth.update({password: newPassword})
  }

  // @ts-ignore
  async function updateProfile({ sms_number }) {
    try {
      setLoading(true)
      const user = supabase.auth.user()
      if(user == null) {
        setLoading(false)
        return
      }

      const updates = {
        id: user.id,
        sms_number,
        updated_at: new Date(),
      }

      let { error } = await supabase.from('profiles').upsert(updates, {
        returning: 'minimal', // Don't return the value after inserting
      })
      if (error) {
        throw error
      }
    } catch (error: any) {
      alert(error.message)
    } finally {
      setLoading(false)
    }
  }

  return (
    <div className="">
      <Button
          startIcon={<LogoutIcon/>}
        onClick={() => supabase.auth.signOut()}
      >
        Sign out
      </Button>
      <Paper sx={{
        marginTop: '1rem',
        maxWidth: '900px',
      }}>
        <Box sx={{
          padding: '1rem',
        }}>
          {/*<CssBaseline />*/}
          <Box
              sx={{
                marginTop: 2,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'left',
              }}
          >
            <Typography component="h1" variant="h5">
              Account
            </Typography>
            <Box component="form" noValidate sx={{ mt: 3 }}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                      name="email"
                      required
                      fullWidth
                      id="email"
                      label="email"
                      disabled={true}
                      value={session.user.email}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                      required
                      fullWidth
                      id="smsNumber"
                      label="SMS Number"
                      name="smsNumber"
                      onChange={(e) => setSmsNumber(e.target.value)}
                      value={sms_number || ''}
                  />
                </Grid>
              </Grid>
              <Button
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                  onClick={() => updateProfile({ sms_number })}
              >
                Update
              </Button>
            </Box>
          </Box>
        </Box>
      </Paper>

      <Paper sx={{
        marginTop: '1rem',
        maxWidth: '900px',
      }}>
        <Box sx={{
          padding: '1rem',
        }}>
          {/*<CssBaseline />*/}
          <Box
              sx={{
                marginTop: 2,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'left',
              }}
          >
            <Typography component="h1" variant="h5">
              Change Password
            </Typography>
            <Box component="form" noValidate sx={{ mt: 3 }}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                      name="newPassword"
                      required
                      fullWidth
                      id="newPassword"
                      label="New Password"
                      value={newPassword || ''}
                      onChange={(e) => setNewPassword(e.target.value)}
                      type="password"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                      required
                      fullWidth
                      id="confirmPassword"
                      label="Confirm Password"
                      name="confirmPassword"
                      value={newPasswordConfirm || ''}
                      onChange={(e) => setNewPasswordConfirm(e.target.value)}
                      type="password"
                  />
                </Grid>
              </Grid>
              <Button
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                  disabled={!changePasswordButtonEnabled}
                  onClick={() => updatePassword({ newPassword })}
              >
                Change
              </Button>
            </Box>
          </Box>
        </Box>
      </Paper>

    </div>
  )
}