import {Container, Typography} from '@mui/material'
import React, {useEffect, useState} from 'react'
import {SignIn} from './components/SignIn'
import { supabase } from './supabaseClient'
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

export default function Auth() {
    const [loading, setLoading] = useState(false)
    const [loginError, setLoginError] = useState<string|null>(null)
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const handleMagicLinkLogin = async (email: string) => {
        try {
            setLoginError(null)
            setLoading(true)
            const { error } = await supabase.auth.signIn({ email })
            if (error) throw error
            setLoginError("Check your email for the login link!")
        } catch (error: any) {
            setLoginError(error.error_description || error.message)
        } finally {
            setLoading(false)
        }
    }

    const handleEmailPasswordLogin = async (email: string, password: string) => {
        try {
            setLoginError(null)
            setLoading(true)
            const { error } = await supabase.auth.signIn({ email, password })
            if (error) throw error
        } catch (error: any) {
            // alert(error.error_description || error.message)
            setLoginError(error.error_description || error.message)
        } finally {
            setLoading(false)
        }
    }

    return (
        <div>
            {isSmallScreen &&
                <div className="flex flex-grow m-10">
                  <img src="grabacamp-logo.svg" alt="logo" />
                </div>
            }
            <SignIn
                handleEmailPasswordLogin={handleEmailPasswordLogin}
                handleMagicLinkLogin={handleMagicLinkLogin}
                loginError={loginError}
                loading={loading}/>
        </div>
    )
}